import { JsonValue } from 'Json';

// common between widget/selector templates
export interface PublicInfoDto {
  information: string;
  longInformationHtml: string | null;
  contacts: string | null;
  sources: string | null;
  tags: string | null;
}

export interface TemplateDto {
  name: string;
  webComponent: WebComponentDto;
  publicInfo: PublicInfoDto;
  selectorIds?: number[]; // SelectorTemplateDto.id
  widgetIds?: number[]; // WidgetTemplateDto.id
  identifier: string;
}

export enum ChartType {
  PIE_CHART = 'PIE CHART',
  DONUT = 'DONUT',
  LINE_CHART = 'LINE CHART',
  COLUMN_CHART = 'COLUMN CHART',
  STOCK_CHART = 'STOCK CHART',
  BAR_CHART = 'BAR CHART',
  BUBBLE_CHART = 'BUBBLE CHART',
  GANTT_CHART = 'GANTT CHART',
  SCATTER_CHART = 'SCATTER CHART',
  TABLE = 'TABLE',
  TEXT = 'TEXT',
  KPI = 'KPI',
  FIGURE = 'FIGURE',
  NUMBER = 'NUMBER',
  HEATMAP = 'HEATMAP',
  AREA_CHART = 'AREA CHART',
  LIST = 'LIST',
  GAUGE = 'GAUGE',
  WORD_CLOUD = 'WORD CLOUD',
  MULTI_CHART = 'MULTI CHART',
  PIVOT_TABLE = 'PIVOT TABLE',
  RADAR_CHART = 'RADAR CHART',
  TREE_MAP = 'TREE MAP',
  WORLD_MAP = 'WORLD MAP',
  NAVIGATION = 'NAVIGATION',
  SANKEY_CHART = 'SANKEY CHART',
  ORG = 'ORG CHART',
  OTHER = 'OTHER',
}

export interface WidgetTemplateCreateDto extends TemplateDto {
  createdAt: string | null;
  minWidth: number | null;
  maxWidth: number | null;
  defaultWidth: number;
  minHeight: number | null;
  maxHeight: number | null;
  defaultHeight: number;
  chartType: ChartType;
  detailBoardId: number | null;
  relatedBoardIds: number[];
}

// WidgetViewDto in Java code
export interface WidgetTemplateDto extends WidgetTemplateCreateDto {
  id: number;
  implementPptExport: boolean;
  updatedAt: string | null;
  publicWidget: boolean;
  sharedByUser: boolean;
  receivedByUser: boolean;
  official: boolean;
  hidden?: boolean;
  designStudio: boolean;
  securedSourceIdentifier: string | null;
  inProject: boolean;
}

export interface WidgetCreateDto {
  name: string;
  widgetTemplateId: number;
  xposition: number;
  yposition: number;
  width: number;
  height: number;
  internalConfiguration: string | null;
  configuration: string | null;
  boardLayoutParentId: number | null;
}

export type WebComponentPropertiesDto = { [property: string]: JsonValue };
export type WebComponentAttributesDto = { [attribute: string]: string };

export enum BootstrapVersion {
  Three = '3',
  Four = '4',
  Five = '5',
}

// common between widget/selector templates
export interface WebComponentDto extends WebComponentBetaFeatures {
  id?: number;
  identifier: string;
  bsVersion: BootstrapVersion;
  baseUrl: string | null;
  baseUrlBs4: string | null;
  baseUrlBs5: string | null;
  outputEvents?: { [event: string]: string };
  inputDataProperty: string | null;
  properties?: WebComponentPropertiesDto;
  attributes?: WebComponentAttributesDto;
  userSelection: string | null; // from the doc: Used to apply default values, when defined value it should be an encoded string (with Javascript encodeURIComponent function) of the JSON contents
  shadowDomIncompatible: boolean | null;
  onlySgNetwork: boolean | null;
  logKeyAttribute: string | null;
  eventWhenInputProcessed: boolean;
  prerequisiteJs: string | null;
  dataSourceIdentifiers?: string[]; // @TODO: once the field is available in PROD, remove the optional and replace string with 'string | null' and change the equivalent field in metadata.ts
  confidentialityLevel: string | null;
  scopes: string[];
}
export interface WebComponentBetaFeatures {
  withoutInitialEvent?: boolean;
}
