import React, { useCallback, useImperativeHandle, useRef, useState, useEffect } from 'react';
import {
  useSgWidget,
  BoardLightAppInfo,
  FakeProducer,
  Tasks,
  ProducerDictionary,
  SimpleScheduler,
  SimpleProducerTaskDictionary,
  registerSourceToSgDashApiwithParams,
  getSgConnectEnvironment
} from 'sg-dashboard-dashboard-common-code';
import { useWidgetConfiguration, widgetize, WidgetPropsMapping } from '@sg-widgets/react-core';
import { WidgetTemplateDto } from 'sg-dashboard-sdk';
import { ErrorMsg, useEmptyStates } from '../common/EmptyStates';
import { Loading } from '../common/Loading';
import { SourceDto } from './utils';
export enum SgConnectEnvironment {
  Homologation = 'homologation',
  Production = 'production'
}
enum BootstrapVersion {
  Three, // BS3
  Four, // BS4
  Five // BS5
}

const appInfo = new BoardLightAppInfo({
  environment: 'homologation' as any,
  sgConnectEnvironment: 'homologation' as any
});

const envUrls: { [key: string]: string } = {
  dev: 'https://sg-dashboard-dev.fr.world.socgen',
  dev2: 'https://sg-dashboard-dev2.fr.world.socgen',
  uat: 'https://sg-dashboard-uat.fr.world.socgen',
  uat2: 'https://sg-dashboard-uat2.fr.world.socgen',
  prd: 'https://dashboard.sgmarkets.com'
};

const defaultKey = '155';

interface WidgetTemplate extends WidgetTemplateDto {
  official: boolean;
}

interface SgWidgetParameters {
  key: string;
  tagName: string;
  type: any; //SgWidgetType,
  outputEvent: string | null;
  inputs: any; // InputData | null,
  inputsProperty: string | null;
  configuration: any; //JsonValue,
  properties: any; // WebComponentPropertiesDto,
  attributes: any; // WebComponentAttributesDto,
  shadowDomIncompatible: boolean;
  bootstrapVersion: BootstrapVersion;
  width: string;
  newProducer: boolean;
  hidden: boolean | null; // null when the info (hidden/shown) is not filled
  logKeyAttribute: string | null;
  persistConfiguration: boolean;
  withPredecessorInConfig: boolean;
  templateIdentifier: string;
  dataSourceIdentifiers: string[];
}

function generateInputs(inputData: string | undefined): any {
  let inputs;
  if (inputData != null) {
    try {
      JSON.parse(inputData);
    } catch (err) {
      throw new Error(`Cannot stringify input-data ${inputData}`);
    }
    const parsedInputData = JSON.parse(inputData);
    inputs = { seqNum: 0, predecessors: [{ __from__: 'GlobalSelector', ...parsedInputData }] };
  } else {
    inputs = { seqNum: 0, predecessors: [{ __from__: 'GlobalSelector' }] };
  }
  return inputs;
}

function createWidgetInfoFromWidgetTemplate(
  widgetTemplate: WidgetTemplateDto,
  widgetType: string,
  inputData: string | undefined
): SgWidgetParameters {
  const outputEventKeys = widgetTemplate.webComponent.outputEvents
    ? Object.keys(widgetTemplate.webComponent.outputEvents)
    : [];
  let outputEvent: string | null = null;
  if (outputEventKeys.length) {
    outputEvent = outputEventKeys[0];
  }
  const newProducer = widgetTemplate.webComponent.eventWhenInputProcessed
    ? widgetTemplate.webComponent.eventWhenInputProcessed
    : false;

  const inputs = generateInputs(inputData);

  return {
    key: defaultKey,
    tagName: widgetTemplate.webComponent.identifier,
    type: widgetType,
    outputEvent: outputEvent,
    inputs: inputs,
    inputsProperty: widgetTemplate.webComponent.inputDataProperty || 'inputData',
    configuration: widgetTemplate.webComponent.userSelection,
    properties: widgetTemplate.webComponent.properties || {},
    attributes: widgetTemplate.webComponent.attributes || {},
    shadowDomIncompatible: !!widgetTemplate.webComponent.shadowDomIncompatible,
    bootstrapVersion: widgetTemplate.webComponent.bsVersion,
    width: 'auto',
    newProducer,
    hidden: false,
    logKeyAttribute: widgetTemplate.webComponent.logKeyAttribute,
    persistConfiguration: false,
    withPredecessorInConfig: false,
    templateIdentifier: widgetTemplate.identifier,
    dataSourceIdentifiers: widgetTemplate.webComponent.dataSourceIdentifiers || []
  };
}

enum WidgetType {
  WIDGET = 'widget',
  SELECTOR = 'selector'
}

enum DashboardCommonCodeEvent {
  LOADED = 'LOADED',
  ERROR = 'ERROR'
}
interface DashboardWidgetProps {
  environment: string;
  identifier: string;
  type: string;
  inputData?: string;
  emitStatus: (data: { lifecycle: DashboardCommonCodeEvent }) => void;
}
interface ReactAPI {
  getOutputEvent: () => string;
}

export const DashboardWidget: React.FC<DashboardWidgetProps> = (
  { environment, identifier, type, inputData: inputs = undefined, emitStatus },
  ref
) => {
  const [widgetInfo, setWidgetInfo] = useState<SgWidgetParameters | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      getOutputEvent() {
        if (widgetInfo == null) {
          throw new Error('Cannot get outputEvent when widget is not loaded');
        }
        return widgetInfo.outputEvent;
      }
    }),
    [widgetInfo]
  );

  const {
    start: emptyStatesStart,
    stop: emptyStatesStop,
    loading,
    errorMsg,
    userActionNeeded,
    param,
    requestAccessNeeded
  } = useEmptyStates();
  const [started, setStarted] = useState<boolean>(false);

  const { fetch, bus } = useWidgetConfiguration();
  const tasks = useRef<Tasks | null>(null);

  const getTasks = useCallback(() => {
    if (tasks.current) return tasks.current;
    const producerDico = new ProducerDictionary();
    const scheduler = new SimpleScheduler();
    tasks.current = {
      producerDico,
      taskDico: new SimpleProducerTaskDictionary(producerDico, () => {
        return { producerDico: {}, taskDico: {}, taskGraph: null, scheduler: {} } as any;
      }),
      taskGraph: null,
      scheduler
    };
    producerDico.addSgWidget(new FakeProducer(defaultKey, 'fake producer', 'Widget' as any, [], null));
    return tasks.current;
  }, []);
  useEffect(() => {
    async function fetchWidgets(): Promise<void> {
      const authorization = bus.dangerouslyGetCurrentValue('sg-connect.access-token') as string;
      const headers = {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization
      };
      try {
        if (environment == null || identifier == null || type == null) {
          throw new Error('Invalid parameters please fill environment, identifier and type');
        }
        let fetchUrl = '';
        const sgConnectEnvironment: SgConnectEnvironment = getSgConnectEnvironment();
        const sgDashboardBackUrl =
          envUrls[`${sgConnectEnvironment == SgConnectEnvironment.Production ? environment : `${environment}2`}`];
        if (sgDashboardBackUrl == null) {
          throw new Error('Unsupported type check sgm dashboard components attribute');
        }
        switch (type) {
          case WidgetType.SELECTOR:
            fetchUrl = `${sgDashboardBackUrl}/api/v2/selectors/identifier/${identifier}`;
            break;
          case WidgetType.WIDGET:
            fetchUrl = `${sgDashboardBackUrl}/api/v3/widgets/identifier/${identifier}`;
            break;
          default:
            throw new Error(
              `Unsupported  attribute "type" of widget only ${WidgetType.SELECTOR}, ${WidgetType.WIDGET} are supported`
            );
        }

        const response = await fetch(fetchUrl, {
          mode: 'cors',
          headers
        });

        if (!response.ok) {
          throw new Error(
            `Error while fetching component info code : ${response.status}, Error: ${response.statusText}`
          );
        }
        const data = await response.json();
        if (data != null) {
          const widgetTemplate = data as WidgetTemplate;
          if (!widgetTemplate.inProject && type === WidgetType.WIDGET) {
            throw new Error(`SGM dashboard component can not load widgets that are not part of a project`);
          }
          const widgetInfo = createWidgetInfoFromWidgetTemplate(widgetTemplate, type, inputs);
          setWidgetInfo(widgetInfo);
          if (widgetInfo.tagName.includes('arclab-bi')) {
            const res = await fetch(
              `${sgDashboardBackUrl}/api/v2/data-sources/identifier/${widgetInfo.dataSourceIdentifiers[0]}`,
              {
                mode: 'cors',
                headers
              }
            );

            if (!res.ok) {
              throw new Error(`Error while fetching dataset code : ${response.status}, Error: ${response.statusText}`);
            }
            const data = await res.json();
            const sourceDto = data[0] as SourceDto;
            const w = window as any;
            if (!sourceDto.component.prerequisiteJs && !w.sgDashboard._propsBySource[sourceDto.identifier]) {
              registerSourceToSgDashApiwithParams(
                sourceDto.identifier,
                sourceDto.component.dataUrl || '',
                sourceDto.component.fieldsUrl || '',
                sourceDto.component.dataToken,
                sourceDto.component.fieldsToken,
                sourceDto.component.fieldsDescription || '',
                false,
                environment
              );
            }
          }
          emitStatus({ lifecycle: DashboardCommonCodeEvent.LOADED });
          emptyStatesStop(null);
        } else {
          throw new Error(`Cannot find ${type} with identifier ${identifier} in environment ${environment}`);
        }
      } catch (error) {
        emptyStatesStop(error);
        emitStatus({ lifecycle: DashboardCommonCodeEvent.ERROR });
      }
    }
    if (widgetInfo == null && !started && bus.dangerouslyGetCurrentValue('sg-connect.access-token') != null) {
      setStarted(true);
      emptyStatesStart();
      fetchWidgets();
    }
  }, [
    environment,
    identifier,
    setWidgetInfo,
    emitStatus,
    inputs,
    type,
    emptyStatesStart,
    widgetInfo,
    started,
    emptyStatesStop,
    bus,
    fetch
  ]);
  if (!Object.values(WidgetType).includes(type as WidgetType)) {
    return (
      <ErrorMsg
        msg={`Please change type in sgm dashboard component attribute choose one of the following ${Object.values(
          WidgetType
        ).join(' ')}`}
        userActionNeeded={false}
        param={null}
        requestAccessNeeded={false}
      />
    );
  }
  if (errorMsg) {
    return (
      <ErrorMsg
        msg={errorMsg}
        userActionNeeded={userActionNeeded}
        param={param}
        requestAccessNeeded={requestAccessNeeded}
      />
    );
  }
  if (loading || !widgetInfo) {
    return <Loading />;
  }
  return <DashboardComponentDataLoaded widgetInfo={widgetInfo} getTasks={getTasks} inputs={inputs || '{ }'} />;
};

const DashboardComponentDataLoaded: React.FC<{
  widgetInfo: SgWidgetParameters;
  getTasks: () => any;
  inputs: string;
}> = ({ widgetInfo, getTasks, inputs }) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  useSgWidget(
    divRef,
    widgetInfo.key,
    widgetInfo.tagName,
    widgetInfo.type,
    widgetInfo.outputEvent,
    getTasks,
    generateInputs(inputs), // inputdata
    widgetInfo.inputsProperty,
    widgetInfo.configuration,
    widgetInfo.properties,
    widgetInfo.attributes,
    widgetInfo.shadowDomIncompatible,
    widgetInfo.bootstrapVersion,
    widgetInfo.width,
    widgetInfo.newProducer,
    () => {
      console.log('dispatch');
    },
    widgetInfo.hidden,
    widgetInfo.logKeyAttribute,
    widgetInfo.persistConfiguration,
    widgetInfo.withPredecessorInConfig,
    appInfo,
    widgetInfo.templateIdentifier,
    widgetInfo.dataSourceIdentifiers
  );

  return <div className="h-100" ref={divRef} />;
};
export const DashboardWidgetForwardedRef = React.forwardRef<ReactAPI, DashboardWidgetProps>(DashboardWidget);

const tagName = 'sgm-dashboard-component';
widgetize(
  tagName,
  DashboardWidgetForwardedRef,
  {
    environment: WidgetPropsMapping.asString(),
    type: WidgetPropsMapping.asString(),
    identifier: WidgetPropsMapping.asString(),
    inputData: WidgetPropsMapping.asString(),
    emitStatus: WidgetPropsMapping.asEventEmitter(`status`)
  },
  {
    neverInjectGlobalCss: true,
    neverUseShadowDOM: true,
    exportReactAPI: {
      getOutputEvent: 'Get output event of a widget'
    }
  }
);
